import React, { useEffect, useState, useCallback } from 'react';
import { Box, Toolbar } from '@mui/material';
import useStyles from './index.style';
import Desktop from './Desktop';

const Header = (props) => {
  const styles = useStyles();
  const [isVisible, setIsVisible] = useState(true);
  const [isAtTop, setIsAtTop] = useState(true);
  const lastScrollY = React.useRef(0);
  const ticking = React.useRef(false);

  const handleScroll = useCallback(() => {
    if (!ticking.current) {
      requestAnimationFrame(() => {
        const currentScrollY = window.scrollY;
        setIsVisible(currentScrollY < lastScrollY.current);
        setIsAtTop(currentScrollY === 0);
        lastScrollY.current = currentScrollY;
        ticking.current = false;
      });
      ticking.current = true;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <Box
      className={styles.container}
      style={{
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translateY(0)' : 'translateY(-100%)',
        transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
        position: isAtTop ? null : 'fixed',
        width: '100%',
        top: 0,
        left: 0,
        zIndex: 1000,
      }}
    >
      <Desktop {...props} />
      <Toolbar className={styles.toolbar} />
    </Box>
  );
};

export default Header;
